<template>
  <div>
    <ul class="tw-flex tw-justify-center tw-items-start">
      <li
        v-for="legendItem in legendItems"
        :key="legendItem.text"
        :class="legendItemClass"
      >
        <span
          class="tw-inline-block tw-rounded-full tw-w-3 tw-h-3 tw-mr-1.5 tw-align-middle"
          :style="{ backgroundColor: legendItem.color }"
        />
        <span class="lg:tw-whitespace-nowrap tw-text-center">
          {{ legendItem.text }}
        </span>
      </li>
      <li
        v-if="isEstimated"
        class="tw-inline-block tw-text-xs"
      >
        <EstimatedBadge size="sm" />
      </li>
    </ul>
    <p
      v-if="description"
      class="tw-mt-0.5 tw-text-xs tw-text-center text-regular-color"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'WeatherStationChartLabel',

  props: {
    description: {
      type: String,
      default: null,
    },
    isEstimated: {
      type: Boolean,
      default: false,
    },
    legendItems: {
      type: Array,
      required: true,
      validator(legendItems) {
        return legendItems.every((legendItem) => (legendItem.color && legendItem.text));
      },
    },
  },

  computed: {
    legendItemClass() {
      return 'tw-inline-block tw-mr-2 tw-text-sm tw-flex tw-justify-center tw-items-center';
    },
  },
};
</script>
