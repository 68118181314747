<script setup>
import { availableViews, useWeatherStation } from '@@/composables/useWeatherStation';
import WeatherStationCharts from '@@/components/WeatherStations/WeatherStationCharts.vue';
import WeatherStationCurrentConditions from '@@/components/WeatherStations/WeatherStationCurrentConditions.vue';
import WeatherStationData from '@@/components/WeatherStations/WeatherStationData.vue';

const props = defineProps({
  hours: {
    type: Number,
    default: null,
  },
  inLocationPage: {
    type: Boolean,
    default: false,
  },
  inMapPage: {
    type: Boolean,
    default: false,
  },
  inWeatherStationDialog: {
    type: Boolean,
    default: false,
  },
  slug: {
    type: String,
    default: null,
  },
  view: {
    type: String,
    default: null,
  },
  weatherStation: {
    type: Object,
    default: null,
  },
});

// When rendered in the /weather-station/:slug page then the <WeatherStationPageContent> will be
// passed the hours, view, and weather station as props from the parent component. The parent
// component will be responsible for managing state. However, when those props are not specified
// and a slug is instead specified, then the <WeatherSTationPageContent> will manage the state
// itself. In both cases, the weather station state is provided and managed by the useWeatherStation()
// composable.

const shouldUseProps = computed(() => typeof props.hours === 'number'
  && typeof props.view === 'string'
  && typeof props.weatherStation === 'object',
);

let fetchWeatherStation;
let myHours;
let myView;
let myWeatherStation;

if (shouldUseProps.value) {
  myHours = computed(() => props.hours);
  myView = computed(() => props.view);
  myWeatherStation = computed(() => props.weatherStation);
}
else {
  ({
    fetchWeatherStation,
    hours: myHours,
    view: myView,
    weatherStation: myWeatherStation,
  } = useWeatherStation({ slug: props.slug }));
}

const $emit = defineEmits(['hours-change', 'view-change']);
const $style = useCssModule();
const { inAppView } = useInAppView();

const canShowHours = computed(() => myView.value !== 'current-conditions');

const componentContainerClass = computed(() => {
  const classes = [
    'tw-pb-4 lg:tw-pb-8',
  ];

  if (props.inWeatherStationDialog) {
    classes.push('tw-overscroll-contain tw-overflow-y-auto');

    if (canShowHours.value) {
      classes.push($style.componentContainerWithHours);
    }
    else {
      classes.push($style.componentContainer);
    }
  }

  return classes;
});

const containerClass = computed(() => props.inWeatherStationDialog ? 'tw-h-full tw-overflow-hidden' : '');

const headerClass = computed(() => {
  if (inAppView.value) {
    return 'tw-mb-1.5 lg:tw-mb-3';
  }

  if (props.inWeatherStationDialog) {
    return 'tw-mt-1 lg:tw-mt-2 tw-mb-2 lg:tw-mb-4';
  }

  return 'tw-my-3 lg:tw-mt-6 lg:tw-mb-7';
});

const selectedTab = computed(() => availableViews.findIndex((availableView) => myView.value === availableView));

const tabsClass = computed(() => {
  if (inAppView.value && canShowHours.value) {
    return 'tw--mt-1.5';
  }

  if (inAppView.value) {
    return 'tw-mb-0.5 tw--mt-1.5 lg:tw-mb-1 lg:tw--mt-3';
  }

  return canShowHours.value ? 'tw-mb-2' : 'tw-mb-2 lg:tw-mb-4';
});

const viewComponent = computed(() => {
  if (myView.value === 'current-conditions') {
    return WeatherStationCurrentConditions;
  }

  if (myView.value === 'charts') {
    return WeatherStationCharts;
  }

  return WeatherStationData;
});

const handleHoursInput = async (newHours) => {
  if (shouldUseProps.value) {
    $emit('hours-change', newHours);
  }
  else {
    myHours.value = newHours;
  }
};

const setView = (index) => {
  if (shouldUseProps.value) {
    $emit('view-change', availableViews[index]);
  }
  else {
    myView.value = availableViews[index];
  }
};

onMounted(async () => {
  if (shouldUseProps.value === false) {
    myWeatherStation.value = await fetchWeatherStation();
  }
});
</script>

<template>
  <div
    v-if="myWeatherStation"
    :class="containerClass"
  >
    <div :class="headerClass">
      <WeatherStationHeader
        :in-location-page="inLocationPage"
        :in-map-page="inMapPage"
        :in-weather-station-dialog="inWeatherStationDialog"
        :is-page-header="!inWeatherStationDialog"
        :weather-station="myWeatherStation"
      />
    </div>
    <Tabs
      :bottom-border="true"
      :class="tabsClass"
      :list-class-names="$style.list"
      :selected-tab="selectedTab"
      @click="setView"
    >
      <Tab
        title="Current Conditions"
      />
      <Tab
        title="Charts"
      />
      <Tab
        title="Data"
      />
    </Tabs>
    <transition
      enter-active-class="fade-enter-transition"
      enter-from-class="fade-out"
      leave-active-class="fade-leave-transition"
      leave-to-class="fade-out"
      tag="div"
    >
      <WeatherStationHours
        v-if="canShowHours"
        key="WeatherStationHours"
        container-class="tw-mb-2 lg:tw-mb-4"
        :model-value="myHours"
        @update:model-value="handleHoursInput"
      />
    </transition>
    <div :class="componentContainerClass">
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
        tag="div"
      >
        <Component
          :is="viewComponent"
          :key="myView"
          :hours="myHours"
          :weather-station="myWeatherStation"
        />
      </transition>
    </div>
  </div>
</template>

<style module>
/**
 * Hide scrollbar yet allow content to still be scrolled!
 * SEE: https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll#answer-49278385
 */
.list {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
}

.list::-webkit-scrollbar { /* WebKit */
  height: 0;
  width: 0;
}

.componentContainer {
  height: calc(100% - 8.75rem);
}

.componentContainerWithHours {
  height: calc(100% - 11.75rem);
}
</style>
